import React from 'react'
import { graphql } from 'gatsby'
import {
  Container,
} from 'reactstrap'
import Header from '../components/pages/common/header'
import Layout from '../components/layout'
import Page from '../components/page'
import Helmet from 'react-helmet'

const BookPage = ({ data }) => {
  const { bookwhenAccount } = data.site.siteMetadata

  return (
    <Layout>
      <Helmet>
        <title>Book | Whickham Thorns Outdoor Activity Centre</title>
      </Helmet>
      <Header
        headerImage={data.headerImage}
        title="Book"
        subtitle="Check out our actvities and book your visit"
        height="20rem"
      />
      <Page>
        <Container>
          {bookwhenAccount && (
            <iframe
              title="Bookwhen"
              src={`https://bookwhen.com/${bookwhenAccount}/iframe`}
              frameBorder="0" 
              scrolling="yes"
              seamless="seamless"
              style={{ width: '100%', height: '50rem' }}
            />
          )}
        </Container>
      </Page>
    </Layout>
  )
}

export const query = graphql`
  query BookQuery {
    site {
      siteMetadata {
        title
        bookwhenAccount
      }
    }
    headerImage: file(relativePath: { regex: "/volunteer/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
      }
    }
  }
`

export default BookPage
